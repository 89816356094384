import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";

function App() {
  console.log("listening");
  const [alxUser, setAlxuser] = useState(null);
  const [parentParams, setParentParams] = useState(null);
  useEffect(() => {
    if (!parentParams) {
      return;
    }
    console.log("parentParams", parentParams);
    fetch(`${findParamValue('endpoint')}/api/users/me/`, {
      headers: {
        Authorization: `JWT ${parentParams?.user_details?.access_token}`,
      },
    })
      .then((resp) => resp.json())
      .then((d) => {
        console.log(d);
        setAlxuser(d);
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentParams]);

  window.addEventListener("message", function (event) {
    if (event?.data?.user_details) {
      setParentParams(event.data);
    }
    console.log("Message received from the parent: ", event.data); // Message received from parent
  });
  const findParamValue = (name) => {
    const value = parentParams?.component_parameters?.find(
      (param) => param.parameter_name === name
    )?.parameter_value

    return value;
  }

  const allParams = () => {
    return parentParams?.component_parameters?.map((param) => {
      return (
        <p key={param.name}>{param.parameter_name}: {param.parameter_value}</p>
      )
    })
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>UserId: {parentParams?.user_id}</p>
        <p>String Param {findParamValue('string_parameter')}</p>
        <p>Integer Param {findParamValue('integer_parameter')}</p>
        <p>Float Param {findParamValue('float_parameter')}</p>
        <p>Boolean Param {findParamValue('boolean_parameter')}</p>
        <h2>ALL PARAMETERS LISTING</h2>
        {allParams()}
        <p>User:</p>
        <div style={{ textAlign: "left" }}>
          <pre>{JSON.stringify(alxUser, null, 4)}</pre>
        </div>
      </header>
    </div>
  );
}

export default App;
